<template>
  <div class="login">
    <div class="tit">
      <h3>绑定手机号</h3>
    </div>
    <div class="loginDiv">
      <div class="phone">
        <p>手机号</p>
        <div class="inputdiv">
          <van-field
            placeholder="请输入手机号"
            type="text"
            v-model="form.phoneNumber"
          />
        </div>
      </div>
      <div class="phone" style="margin-top: 30px">
        <p>验证码</p>
        <div class="inputdiv">
          <van-field
            placeholder="请输入验证码"
            type="text"
            v-model="form.verifyCode"
          />
          <p class="codeBtn" @click="getVerifyCode">
            {{ counts }} <span v-show="counts !== '获取验证码'">S</span>
          </p>
        </div>
      </div>
      <div class="loginBtn">
        <van-button round color="#FF804D" type="info" @click="login"
          >绑定</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { validPhone } from "@/utils/validate";
import { getVerifyCode, login,getShopName } from "../../utils/api";
export default {
  components: {},
  data() {
    return {
      form: {
        phoneNumber: "",
        verifyCode: "",
      },
      isChecked: false,
      shopId: "",
      counts: "获取验证码",
      timer: null,
      show: true,
      shopMsg:{}
    };
  },
  created() {
    this.getshopName()
    // this.shopId = window.location.pathname.split("/")[1];
  },
  methods: {
    //获取店铺信息
    async getshopName() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await getShopName().then((res) => {
        this.shopMsg = res;
        console.log(res)
        this.shopId = res.id;
         this.$toast.clear();
      });
    },
    // 获取验证码
    getVerifyCode() {
      if (this.counts === "获取验证码") {
        if (this.form.phoneNumber === "") {
          this.$toast("请输入手机号码");
        } else if (!validPhone(this.form.phoneNumber)) {
          this.$toast("手机号码格式不正确");
        } else {
          this.$toast.loading({
            message: "加载中...",
            forbidClick: true,
            duration: 0,
          });
          let params = {
            shopId:this.shopId,
            phoneNumber: this.form.phoneNumber,
          };
          getVerifyCode(params)
            .then((res) => {
              if (res.success) {
                let TIME_COUNT = 60;
                if (!this.timer) {
                  this.counts = TIME_COUNT;
                  this.show = false;
                  this.timer = setInterval(() => {
                    if (this.counts > 1 && this.counts <= TIME_COUNT) {
                      this.counts--;
                      if (this.counts < 10) {
                        this.counts = `0${this.counts}`;
                      }
                    } else {
                      this.counts = "获取验证码";
                      this.show = true;
                      clearInterval(this.timer);
                      this.timer = null;
                    }
                  }, 1000);
                }
                this.$toast.clear();
              }
            })
            .catch(() => {
              this.$toast.clear();
            });
        }
      } else {
        this.$toast("请勿重复点击");
      }
    },
    //绑定手机号
    login() {
      if (this.form.phoneNumber === "") {
        this.$toast("请输入手机号码");
      } else if (!validPhone(this.form.phoneNumber)) {
        this.$toast("手机号码格式不正确");
      } else if (this.form.verifyCode === "") {
        this.$toast("请输入验证码");
      }else {
        this.$toast.loading({
          message: "登录中...",
          forbidClick: true,
          duration: 0,
        });
        let params = {
          phoneNumber: this.form.phoneNumber,
          verifyCode: this.form.verifyCode,
          shopId:this.shopId
        };
        login(params)
          .then((res) => {
            console.log(res);
            if (res.success) {
              this.$toast.clear();
              this.$router.go(-1);
            }
            // this.$router.push({
            //   path: `/${this.shopId}/home`,
            // });
          })
          .catch(() => {
            // this.$toast.clear();
          });
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
$primary-color: #ff8757;
.login {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 47px 27px 0;
  background: url("../../assets/loginBg.png") no-repeat,
    linear-gradient($primary-color, #ffe3b8);
  .tit {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 74px;
    flex-direction: column;
    h3 {
      font-size: 20px;
      color: #fff;
      font-weight: 20px;
    }
  }
  .loginDiv {
    padding: 30px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    .phone {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .inputdiv {
        width: 100%;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        align-items: center;
        .codeBtn {
          font-size: 12px;
          font-weight: 500;
          color: $primary-color;
          line-height: 17px;
          cursor: pointer;
          width: 100px;
          display: flex;
          justify-content: center;
        }
      }
      /deep/ .van-cell {
        padding: 10px 0;
      }
      p {
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
        margin-bottom: 5px;
      }
    }
  }
  .active {
    background: $primary-color;
  }
  .noactive {
    background: #949292;
  }
  .loginBtn {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    /deep/ .van-button {
      width: 100%;
      height: 41px;
    }
  }
}
</style>